.addListItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
  width: 290px;
  border: none;
  margin: 5px 0px;
}

.addListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: rgb(104, 104, 104);
  background-color: rgb(233, 233, 233);
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 56px;
  height: 50px;
  cursor: pointer;
  margin-left: 5px;
}

.addListItem:hover {
  background-color: rgb(206, 206, 206);
}

.allDayEventContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0px 5px 0px;
}

.callToActionDropdown {
  display: inline-block;
  width: 250px;
  height: 48px;
  margin: 4px 0px 20px 0px;
  z-index: 3;
  padding: 0.5%;
  background-color: rgb(233, 233, 233);
  color: rgb(92, 92, 92);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
}

.callToActionWrapper {
  width: 100%;
}

.dateTimeInputContainer {
  width: 290px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editableSmallInputBoxCTA {
  width: 100%;
  max-width: 300px;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px 0px;
  font-size: 15px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
}

.editableSmallInputBoxDateTimeCTA {
  width: 240px;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px 0px;
  font-size: 15px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
}
select::-ms-expand {
  padding-right: 8px;
}

.editableLargeInputBoxCTA {
  width: 100%;
  max-width: 300px;
  height: 200px;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px 0px;
  font-size: 15px;
  border: 1px solid rgb(180, 180, 180);
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 3px;
}

.isAllDay {
  pointer-events: none;
  color: gray;
  opacity: 0.6;
}

.listItem {
  color: white;
  background-color: #38ac74;
  width: 82%;
  border: none;
  border-radius: 5px;
  padding: 10px 12px;
}

.listItemContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  border: none;
  margin: 5px 0px;
}

.notAllDay {
  width: 100%;
}

.prepareList {
  width: 100%;
  font-weight: 400;
  height: 200px;
  overflow-y: scroll;
}

.prepareList::-webkit-scrollbar {
  display: none;
}

.removeListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(104, 104, 104);
  background-color: rgb(233, 233, 233);
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 18%;
  height: 50px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
}

.removeListItem:hover {
  background-color: rgb(206, 206, 206);
}

.scrollDownArrow {
  color: rgb(134, 134, 134);
  width: 20px;
  margin: 0 auto;
  transform: scale(1.5);
  margin-top: 10px;
}
.addButton {
  color: #ffffff;
  font-weight: 500;
  background-color: #1180b8;
  padding: 10px 30px;
  margin: 30px auto 10px;
  height: 48px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  transition: 0.1s;
}

.addButton:hover {
  background-color: #249bd6;
}

.callToActionButton {
  display: inline-block;
  width: 100%;
  height: 48px;
  margin: 4px 0px;
  z-index: 3;
  padding: 0.5%;
  background-color: rgb(233, 233, 233);
  color: rgb(92, 92, 92);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
  cursor: pointer;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bugButton {
  color: white;
  flex: 3;
  text-align: center;
  cursor: pointer;
}

.bugDiv {
  display: inline-flex;
  background-color: rgb(58, 58, 58);
  position: fixed;
  width: 120px;
  height: 25px;
  right: 10px;
  bottom: 10px;
  padding: 5px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.bugEmailInfo {
  text-align: center;
  color: white;
  padding-top: 10px;
  font-size: 18px;
}

.bugFinePrint {
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bugIcon {
  color: white;
  height: 100%;
  flex: 1;
}

.bugInfo {
  width: 98%;
  height: 100px;
  font-family: Arial, Helvetica, sans-serif;
}

.bugTitle {
  color: white;
  text-align: center;
  font-size: 25px;
}

.chooseFileButton {
  height: 30px;
  margin-right: 5px;
  color: white;
}

.chooseFileDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.saveButton {
  width: 150px;
  border-radius: 20px;
  font-size: 16px;
  height: 35px;
  background-color: lightgray;
  cursor: pointer;
}

.sendButton {
  width: 150px;
  border-radius: 20px;
  font-size: 16px;
  height: 35px;
  cursor: pointer;
}

.sendOrSaveDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-around;
}

.accessDeniedContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40vh;
  width: 35%;
  margin: -200px auto 0px;
  padding: 20px 80px 50px;
  color: rgb(109, 109, 109);
  border-radius: 5px;
  background: #f5f7f8;
  box-shadow: 6px 6px 12px #a9acae;
}

.accessDeniedPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #ebf0f3;
}

.accessDeniedButton {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #38ac74;
  border: none;
  border-radius: 3px;
  padding: 8px 25px;
  font-weight: 500;
}

.accessDeniedButton:hover {
  background-color: #40bb80;
  cursor: pointer;
}

.active-dotDotDotDropdown {
  background-color: rgb(58, 58, 58);
  position: fixed;
  margin-top: 70px;
  justify-content: center;
  align-items: center;
  align-content: center;
  right: 27%;
}

.active-dotDotDotArrow {
  margin-top: 45px;
  right: 30.25%;
  position: fixed;
  z-index: 6;
  color: rgb(58, 58, 58);
}

.affiliatedOrgBackground {
  position: fixed;
  right: 10px;
  top: 0;
  width: 26%;
  margin-top: 90px;
  background-color: rgb(58, 58, 58);
  padding: 5px;
  z-index: 0;
}

.affiliatedOrgBackground input {
  width: 80%;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: rgb(233, 233, 233);
  border-radius: 5px;
  outline: none;
  border-style: none;
}

.affiliatedOrgBackground button {
  width: 15%;
  margin: 5px;
  background-color: white;
  color: black;
  border-radius: 5px;
}

.affiliatedOrgSubTitle {
  font-size: 16px;
  text-align: left;
  margin-top: 5px;
  font-weight: 500;
  margin-left: 5px;
  text-align: center;
  color: rgb(182, 182, 182);
}

.affiliatedOrgTitle {
  font-size: 20px;
  text-align: left;
  margin-top: 5px;
  font-weight: 400;
  margin-left: 5px;
  text-align: center;
  color: white;
}

.App {
  height: 100%;
  width: 85%;
  flex-direction: column;
  margin-left: 15%;
  padding-top: 70px;
}

.App-header {
  height: 100vh;
  margin-left: 5px;
}

.callsToActionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0px 20px 0px;
  justify-content: flex-start;
  align-items: flex-start;
}

.callToActionIndividualContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 18%;
  margin-right: 2%;
}

.callToActionIcon {
  height: 40px;
  width: 185px;
  background-color: rgb(233, 233, 233);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  color: rgb(92, 92, 92);
}

.callToActionIcon:hover {
  background-color: rgb(209, 209, 209);
  cursor: pointer;
}

.callToActionPreviewContainer {
  width: 100%;
  display: flex;
  padding: 10px;
  height: 300px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: gray;
  color: white;
  border: none;
  border-radius: 3px;
}

.callToActionRemoveContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.callToActionSelected {
  height: 40px;
  width: 185px;
  background-color: #38ac74;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  color: white;
  font-weight: 500;
}

.callToActionSelected:hover {
  cursor: pointer;
}

.createNew {
  margin-left: 20px;
  height: 30px;
  background-color: #38ac74;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  transition: 0.1s;
  padding: 0px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.createNew:hover {
  background-color: #4ac589;
}

.createNewBox {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.createNewButton {
  color: #ffffff;
  font-weight: 500;
  background-color: #1180b8;
  padding: 10px 30px;
  margin: 10px auto 30px;
  height: 48px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  transition: 0.1s;
}

.createNewButton:hover {
  background-color: #249bd6;
}

.createNewButtonContainer {
  width: 58%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0px 40px 0px;
}

.createNewButtonContainerMilestoneType {
  width: 38%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0px 40px 0px;
}

.darkBoxTitle {
  font-size: 14px;
  text-align: left;
  margin-top: 10px;
  font-weight: 500;
}

.darkBoxTitleCallToAction {
  font-size: 14px;
  text-align: left;
  margin: 4px 2% 4px 0px;
  font-weight: 500;
  width: 18%;
}

.darkBackground {
  background-color: rgb(58, 58, 58);
  margin-top: 20px;
  width: 58%;
  border-radius: 3px;
  padding: 15px;
}

.dotDotDotArrow {
  position: absolute;
  top: -500%;
}

.dotdotdotButton {
  height: 30px;
  width: 30px;
  color: white;
  background-color: rgb(58, 58, 58);
  border-radius: 10%;
  padding: 5px;
  right: 30%;
  position: fixed;
  z-index: 0;
}

.dotDotDotDropdown {
  top: -500%;
  position: absolute;
}

.downloadButton {
  height: 30px;
  width: 30px;
  color: white;
  background-color: rgb(58, 58, 58);
  border-radius: 10%;
  padding: 5px;
  display: inline-block;
  margin-left: 3%;
  position: absolute;
}

.downloadButtonGraph {
  height: 30px;
  width: 30px;
  color: white;
  background-color: rgb(58, 58, 58);
  border-radius: 10%;
  padding: 5px;
  display: inline-block;
  margin-left: 3%;
}

.downloadButtonSmallGraph {
  height: 30px;
  width: 30px;
  color: white;
  background-color: rgb(58, 58, 58);
  border-radius: 10%;
  padding: 5px;
  margin-left: 40%;
}

.dropDownOption {
  top: 200px;
  width: 100%;
  display: flex;
  background: none;
  padding: 10px;
  font-size: 15px;
  font-weight: 300;
  z-index: 6;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
  background-color: rgb(58, 58, 58);
}

.dropDown {
  display: inline-block;
  margin-left: 3%;
  margin-bottom: 1%;
  width: 18%;
  z-index: 3;
  padding: 0.5%;
  background-color: rgb(58, 58, 58);
  color: white;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  text-align: center;
}

.dropDownMilestoneTypes {
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 4px 0px;
  z-index: 3;
  padding: 0.5%;
  background-color: rgb(233, 233, 233);
  color: rgb(92, 92, 92);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
}

.dropDownCallToAction {
  display: inline-block;
  width: 100%;
  height: 48px;
  margin: 4px 0px;
  z-index: 3;
  padding: 0.5%;
  background-color: rgb(233, 233, 233);
  color: rgb(92, 92, 92);
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
  margin-right: 2%;
}

.dropDownMilestoneTypesBox {
  width: 18%;
  height: 48px;
}

.editableSmallInputBox {
  width: 38%;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px 0px;
  font-size: 15px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
}

.editableTimeInputBox {
  width: 18%;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px 2% 4px 0px;
  font-size: 15px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
}

.editableLargeInputBox {
  width: 58%;
  height: 120px;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px 0px;
  font-size: 15px;
  border: 1px solid rgb(180, 180, 180);
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 3px;
}

.editButton {
  height: 30px;
  width: 30px;
  color: white;
  background-color: rgb(58, 58, 58);
  border-radius: 10%;
  padding: 5px;
  position: fixed;
  right: 33%;
}

.errorMessageContainerSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(255, 160, 160);
  padding: 0px 20px 20px;
  border-radius: 3px;
}

.evenBodyCell {
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 2;
  background-color: lightgray;
}

.evenBodyCellRank {
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 1;
  background-color: lightgray;
}

.evenLargeBodyCell {
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 3;
  background-color: lightgray;
}

.goalsBox {
  height: 180px;
  background-color: rgb(233, 233, 233);
  width: 38%;
  align-items: center;
  justify-items: center;
  padding: 10px 0px;
  border-radius: 3px;
  border: 1px solid rgb(180, 180, 180);
  margin-right: 20px;
}

.goalColumnsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.goalsOptionsColumn {
  height: 500px;
  width: 100%;
  margin: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
}

.goalsTopColumn {
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.goalColumnTitle {
  color: white;
  font-size: 20px;
  text-align: center;
  margin: 10px 5px;
}

.goalsTopNumbers {
  height: 300px;
  display: flex;
  flex-direction: column;
  margin: 15px;
}

.goalRow {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 33%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.goalRowMilestonePage {
  display: flex;
  flex-direction: row;
  width: 38%;
  justify-content: center;
  align-items: center;
  margin: 4px 0px;
  align-content: center;
  background: rgb(233, 233, 233);
  border: 1px solid rgb(180, 180, 180);
  padding: 10px;
  border-radius: 3px;
}

.goalRowMilestonePage:hover {
  cursor: pointer;
}

.goalMovableItemTop {
  background-color: #38ac74;
  padding: 8px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  height: 40px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.goalMovableItemOption {
  background-color: #78bcde;
  padding: 8px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  height: 40px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  scroll-behavior: auto;
  margin-bottom: 10px;
}

.goalModalNumber {
  color: white;
  font-size: 20px;
  font-weight: 500;
  height: 80px;
  margin-top: 45px;
}

.goalModalSections {
  width: 48%;
}

.goalName {
  color: white;
  background-color: #38ac74;
  padding: 8px;
  flex: 7 1;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
}

.goalNumber {
  flex: 1;
  text-align: center;
  font-size: 16px;
}

.graphTimeDropDown {
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 5px;
  width: 15%;
  border: none;
  font-size: 16px;
}

.graphDropdownDiv {
  width: 100%;
}

.groupIcon {
  display: inline-flex;
  margin-right: 10px;
  height: 40px;
  width: 50px;
}

.headerCell {
  background-color: rgb(62, 66, 66);
  color: white;
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 2;
}

.headerCellRank {
  background-color: rgb(62, 66, 66);
  color: white;
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 1;
}

h1.accessDeniedH1 {
  font-size: 6em;
  font-weight: 700;
  margin: 0;
  color: #1180b8;
}

h3.accessDeniedH3 {
  font-size: 2em;
  font-weight: 700;
  color: rgb(109, 109, 109);
  margin: 0;
}

hr.accessDeniedHR {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 0.5px;
  width: 60%;
  margin: 10px 0px 0px;
  margin-bottom: 20px;
  padding: 0;
}

.largeHeaderCell {
  background-color: rgb(62, 66, 66);
  color: white;
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 3;
}

.largeInputBox {
  width: 60%;
  height: 120px;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px;
  font-size: 15px;
  border-style: none;
  outline: none;
  resize: none;
  font-family: Arial, Helvetica, sans-serif;
}

.lightBoxTitle {
  font-size: 14px;
  text-align: left;
  margin: 5px 0px 0px 5px;
  font-weight: 400;
  display: inline-block;
  color: rgb(182, 182, 182);
}

.lightBoxTitleLong {
  font-size: 14px;
  text-align: left;
  margin-top: 5px;
  font-weight: 400;
  display: inline-block;
  color: rgb(182, 182, 182);
  width: 20%;
}

.milestoneAutofillContainer {
  margin-top: 5px;
}

.modalText {
  color: white;
  font-size: 16px;
  text-align: center;
}

.modalTitle {
  color: white;
  text-align: center;
  font-size: 25px;
}

.oddBodyCell {
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 2;
}

.oddBodyCellRank {
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 1;
}

.oddLargeBodyCell {
  font-size: 16;
  text-align: center;
  align-items: center;
  padding: 10px;
  flex: 3;
}

.optionalText {
  color: rgb(151, 151, 151);
}

.org {
  color: white;
  text-decoration-color: white;
  text-decoration-line: underline;
  font-size: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.orgdotdotdotButton {
  color: rgb(58, 58, 58);
  background-color: white;
  border-radius: 10%;
  height: 16px;
  width: 16px;
  right: 45%;
  position: absolute;
}

.pageHeader {
  display: inline-flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  margin-left: 15px;
}

.pageTitle {
  font-size: 30px;
  font-weight: 600;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.pieChart {
  width: 50%;
  align-items: center;
  margin-bottom: 5px;
}
.plusIcon {
  display: inline-flex;
  height: 13px;
  width: 13px;
  color: rgb(255, 255, 255);
}

.primaryCallToActionBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 60%;
}

progress {
  width: 60%;
  height: 30px;
}

.questionIcon {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.removeCallToAction {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 48px;
  margin: 4px 0px 4px 2%;
  padding: 0.5%;
  background-color: rgb(233, 233, 233);
  color: rgb(92, 92, 92);
  text-align: center;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
  cursor: pointer;
}

.row {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  color: black;
  text-decoration: none;
  align-items: center;
  text-align: center;
}

.SelectionBoxLine {
  flex-direction: row;
  justify-content: space-evenly;
  text-align: center;
  z-index: 3;
  width: 90%;
}

.smallInputBox {
  width: 40%;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 4px;
  font-size: 15px;
  border-style: none;
  outline: none;
}

.smallestUserBox {
  width: 50%;
  border-bottom: 3px solid lightgray;
  border-left: 3px solid lightgray;
  padding-left: 2%;
  padding-right: 2%;
  margin-bottom: 10px;
}

.smallUserBox {
  width: 50%;
  border-bottom: 3px solid lightgray;
  border-left: 3px solid lightgray;
  border-right: 3px solid lightgray;
  padding-left: 2%;
  padding-right: 2%;
}

.smallestUsersOptionBoxs {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-bottom: 20px;
}

.smallUsersOptionBoxs {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.UsersOptionBoxs {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-bottom: 20px;
}

.subTitleAttributes {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 30px;
}

.subtitleWhite {
  font-size: 20;
  color: white;
  font-weight: 600;
}

.successMessageContainerSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgb(160, 255, 200);
  padding: 0px 20px 20px;
  border-radius: 3px;
}

.tableDiv {
  padding-left: 50px;
  width: 90%;
  justify-content: center;
}

.timeInputBox {
  width: 18.5%;
  background-color: rgb(233, 233, 233);
  color: black;
  padding: 12px;
  margin: 3px;
  font-size: 15px;
  border-style: none;
  outline: none;
}

.topThreeGoalsItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.TopThreeGoalsNumber {
  color: white;
  font-size: 20px;
  font-weight: 500;
  width: 20px;
}

.userBox {
  width: 80%;
  border-bottom: 3px solid lightgray;
  border-left: 3px solid lightgray;
  border-right: 3px solid lightgray;
  padding-left: 2%;
  padding-right: 2%;
}

.usersOptionBox {
  flex: 1;
  text-align: center;
  padding-top: 5px;
}

.usersOptionBoxSelected {
  flex: 1;
  text-align: center;
  border-top: 5px solid rgb(88, 155, 255);
}

.usersOptionBoxs {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.usersOptionTitle {
  font-weight: 500;
}

.usersOptionNumber {
  font-size: 20px;
  margin: 3px;
}

.userSelect {
  left: 0;
}

.whiteInputTitle {
  font-size: 14px;
  text-align: left;
  margin-top: 10px 10px 0px 0px;
  font-weight: 500;
  color: white;
}

.zipcodeBox input {
  background-color: white;
}

.zipcodeBox {
  background-color: rgb(233, 233, 233);
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 58%;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 3px;
  padding: 15px;
}

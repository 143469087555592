.goBack {
  text-align: left;
  color: rgb(58, 58, 58);
  font-size: 14px;
  text-decoration-line: underline;
  text-decoration-color: rgb(58, 58, 58);
  padding-bottom: 10px;
}

.nav-menu {
  width: 15%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 70px;
  z-index: 10;
  background-color: white;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0px 8px 8px;
  list-style: none;
  align-items: center;
}

.nav-text a {
  text-decoration: none;
  font-size: 18;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  color: black;
  font-weight: bold;
}

.nav-text-selected {
  background-color: rgb(233, 233, 233);
  display: flex;
  justify-content: flex-start;
  padding: 8px 0px 8px 8px;
  list-style: none;
  border-radius: 5px;
  align-items: center;
}

.nav-text-selected a {
  text-decoration: none;
  font-size: 18;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  color: black;
  font-weight: bold;
}

.nav-text a:hover {
  background-color: rgb(233, 233, 233);
  padding: 5px;
}

.nav-menu-items {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 30px;
}

span {
  margin-left: 10px;
  margin-right: 10px;
}

.specificName {
  text-align: center;
  color: rgb(58, 58, 58);
  font-size: 24px;
  font-weight: bold;
}

.typeOfBar {
  text-align: center;
  color: rgb(155, 155, 155);
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 3px;
}

.children {
  width: 100%;
  height: 100vh;
}

.nav-menu {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  left: -30px;
}

.nav-text {
  padding: 10px;
  list-style: none;
  color: black;
  text-decoration: none;
  font-size: 16px;
}

.nav-text a {
  text-decoration: none;
  font-size: 18;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  color: black;
  font-weight: bold;
}

.nav-text-selected {
  background-color: rgb(233, 233, 233);
  justify-content: flex-start;
  padding: 10px;
  list-style: none;
  border-radius: 5px;
  color: black;
  text-decoration: none;
  font-size: 16px;
}

.nav-text-selected a {
  text-decoration: none;
  font-size: 18;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 5px;
  border-radius: 4px;
  color: black;
  font-weight: bold;
}

.nav-text a:hover {
  background-color: rgb(233, 233, 233);
  padding: 5px;
}

.nav-menu-items {
  width: 80%;
}

span {
  margin-left: 1px;
}

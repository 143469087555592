.active-dropdown {
  background-color: rgb(58, 58, 58);
  position: absolute;
  top: 60px;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
}

.active-arrow {
  position: absolute;
  top: 50px;
  color: rgb(58, 58, 58);
  margin-left: 65px;
}

.arrow {
  position: absolute;
  top: -500%;
}

.buttonDiv {
  margin-left: 3%;
  display: inline-block;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 10;
}
.createButton {
  margin-left: 35px;
  color: white;
  background-color: rgb(58, 58, 58);
  font-weight: 400;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.createDropdownOption {
  width: 100%;
  display: flex;
  color: white;
  background: none;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 400;
  z-index: 8;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.dropdown {
  top: -500%;
  position: absolute;
}

.inputBox {
  width: 80%;
  height: 90%;
  color: grey;
  top: -10;
  flex: 10;
  border-radius: 5px;
}

.inputBoxDiv {
  display: inline-flex;
  background-color: #d1d2d4;
  width: 30%;
  height: 50%;
  margin-left: 5%;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.profileDiv {
  right: 30px;
  display: flex;
  flex-direction: row;
  position: absolute;
  height: 80%;
  align-items: center;
  justify-content: right;
}

.profileIcon {
  height: 30px;
  width: 30px;
  color: rgb(58, 58, 58);
}

.profileName {
  font-weight: 600;
  font-size: 18px;
  padding-right: 5px;
  color: rgb(58, 58, 58);
}

.searchIcon {
  height: 90%;
  width: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: #3a3a3c;
}

.subTitle {
  margin-left: 5%;
  font-weight: 300;
  display: inline-block;
}

.title {
  margin-left: 5vh;
  font-weight: 600;
  display: inline-block;
}

.topbar {
  top: 0;
  position: fixed;
  height: 70px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  background-color: rgb(241, 239, 239);
  z-index: 10;
}
